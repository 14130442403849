// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full screen height */
    background-color: rgba(0, 0, 0, 0.1); /* Light overlay effect */
}

.tiny-loader {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey border */
    border-top: 4px solid #3498db; /* Blue spinner */
    border-radius: 50%;
    width: 30px; /* Smaller width */
    height: 30px; /* Smaller height */
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/css/loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa,EAAE,uBAAuB;IACtC,oCAAoC,EAAE,yBAAyB;AACnE;;AAEA;IACI,oCAAoC,EAAE,sBAAsB;IAC5D,6BAA6B,EAAE,iBAAiB;IAChD,kBAAkB;IAClB,WAAW,EAAE,kBAAkB;IAC/B,YAAY,EAAE,mBAAmB;IACjC,kCAAkC;AACtC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":[".loader-container {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh; /* Full screen height */\r\n    background-color: rgba(0, 0, 0, 0.1); /* Light overlay effect */\r\n}\r\n\r\n.tiny-loader {\r\n    border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey border */\r\n    border-top: 4px solid #3498db; /* Blue spinner */\r\n    border-radius: 50%;\r\n    width: 30px; /* Smaller width */\r\n    height: 30px; /* Smaller height */\r\n    animation: spin 1s linear infinite;\r\n}\r\n\r\n@keyframes spin {\r\n    0% { transform: rotate(0deg); }\r\n    100% { transform: rotate(360deg); }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
