import {useParams} from "react-router-dom";

const Scoreboard =() => {
    const {match_id}  = useParams()
return (
    <div className={`markets cricket_score_board`}>
        <iframe src={`https://api.codewithabhilash.com/ifrm.html?eid=${match_id}`} width="100%" title="scoreboard" />
    </div>
)
}
export default Scoreboard;