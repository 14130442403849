import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {SportsContext} from "../../Components/Context/SportsContext";
import {AuthContext} from "../../Components/Context/AuthContext";
import axiosFetch, {getStakeValue, setdecimalPoint} from "../../Common_Functions/Constants";
import Notify from "../../js/Notify";
import StakeModal from "../../Components/EditStakeModal";

const RightSideBarSports =
    ({
         setPlacingBets = null,
         callTeamDatas = null,
         placingBets = [],

         setPopupDisplay,
         popupDisplay,
         getBetListData= null,
         myBetModel,

         individualBetPlaceFetch,
         odds,
         gameId = null,

         setOdds,
         backOrLay,
         data = {},
         teamNameCurrentBets = {},
         teamNames = {},

         maxValue = 1,
         teamname,
         sportList,
         minValue = 1

     }) => {
        const [isTVVisible, setIsTVVisible] = useState(false);
        const [stakeshowModal, setStakeShowModal] = useState(false);
        const toggleTV = () => {
            setIsTVVisible(!isTVVisible);

        };

        const changeDataRef = useRef({})
        const [isFixed, setIsFixed] = useState(false);
        const [sidebarTop, setSidebarTop] = useState(0);
        const sidebarRef = useRef(null);

        useEffect(() => {
            if (sidebarRef.current) {
                setSidebarTop(sidebarRef.current.offsetTop);
            }

            const handleScroll = () => {
                if (sidebarRef.current) {
                    const scrollTop = window.scrollY;
                    if (scrollTop >= sidebarTop) {
                        setIsFixed(true);
                    } else {
                        setIsFixed(false);
                    }
                }
            };

            window.addEventListener('scroll', handleScroll);

            // Clean up event listener on component unmount
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, [sidebarTop]);

        useEffect(() => {
            if (popupDisplay === true) {
                setPlacingBets([])
            }
            //eslint-disable-next-line
        }, [odds]);



        const {betType, oddsk, betTypeFromArray, stakeValues, setStakeValues} = useContext(SportsContext);


        const stakeValue = useRef(0);

        const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

        const {getBalance} = useContext(AuthContext);
        const [hideLoading, setHideLoading] = useState(false)
        const loss = useRef(0);

        const profit = useRef(0);
        const profitData = useRef(0);

        const {rootClassDefault, runnerRowDefault} = useContext(SportsContext)

        useEffect(() => {
            if (Object.entries(data).length > 0) {
                changeDataRef.current = data
            }
            return () => {
                changeDataRef.current = {}
            }

        }, [data]);
        const handleStakeChange = (event, newodds = null) => {


            const value = event.target.value;
            stakeValue.current = value;


            if (newodds !== null) {
                odds = newodds
            }

            calculateProfit(value, odds);
            const losslayvalue = ((parseFloat(odds)) * parseFloat(value)) / 100

            const lossvalue = (parseFloat(odds) - 1) * parseFloat(value)
            loss.current = backOrLay === 'back' ? parseFloat(setdecimalPoint(value)) : (betType === 'ODDS' ? parseFloat(setdecimalPoint(lossvalue)) : parseFloat(setdecimalPoint(losslayvalue)));

            if (setPlacingBets !== null && ['ODDS', 'BOOKMAKER', 'TIED_MATCH', 'TIED MATCH'].includes(betType)) {


                const updatedBets = teamNames.current[betType].reduce((acc, value) => {
                    if (backOrLay === 'back') {
                        if (teamname.current !== value) {


                            acc[value] = Math.round(parseFloat(-loss.current) + parseFloat(teamNameCurrentBets.current[betType][value]));
                        } else {

                            acc[teamname.current] = Math.round(parseFloat(profit.current) + parseFloat(teamNameCurrentBets.current[betType][value]));
                        }
                    } else {
                        if (teamname.current !== value) {
                            acc[value] = Math.round(parseFloat(profit.current) + parseFloat(teamNameCurrentBets.current[betType][value]));
                        } else {


                            acc[teamname.current] = Math.round(parseFloat(-loss.current) + parseFloat(teamNameCurrentBets.current[betType][value]));
                        }
                    }
                    return acc;
                }, {...placingBets[betType]});  // start with existing state for betType


                setPlacingBets((prevState) => ({
                    ...prevState,
                    [betType]: updatedBets
                }));

            }
        };


        const calculateProfit = (stake, odds) => {
            // Parse odds and stake once for performance
            const parsedOdds = parseFloat(odds);
            const parsedStake = parseFloat(stake);

            let profitCalc = 0;

            if (betType === 'ODDS' || betType === 'ODDEVEN') {
                profitCalc = parsedStake * (parsedOdds - 1);
            } else if (betType === 'SESSION') {
                profitCalc = parsedStake;
            } else if (betType === 'BOOKMAKER' || betType === 'TIED_MATCH') {
                profitCalc = (parsedStake * parsedOdds) / 100;


            } else {
                profitCalc = parsedStake * parsedOdds;
            }

            // Ensure profitCalc is always a number to avoid issues
            profitCalc = isNaN(profitCalc) ? 0 : profitCalc;

            // Store the result directly in the profit reference
            profit.current = profitCalc.toFixed(2);


            // Handle profit/loss logic for back or lay
            if (backOrLay === 'back') {
                if (betType === 'ODDS') {
                    profit.current = profitData.current = profit.current
                } else if (betType === 'BOOKMAKER') {

                    profit.current = profitCalc
                    profitData.current = profitCalc
                } else if (betType === 'ODDEVEN') {
                    profit.current = profitData.current = profit.current
                } else if (betType === 'fancy1') {

                    profit.current = profitData.current = (parsedOdds - 1) * 100;
                } else if (betType === 'TIED_MATCH') {

                    profit.current = profitData.current = (parsedOdds * parsedStake) / 100;
                } else {

                    profit.current = profitData.current = (oddsk.current * parsedStake) / 100;
                }
            } else {

                profitData.current = parsedStake;
                profit.current = parsedStake
            }


        };

        const clearAllSelection = () => {
            stakeValue.current = 0;
            setOdds(0);
            profit.current = 0;
            setPopupDisplay(false)
            setPlacingBets([])
            loss.current = 0
        };
        const resetAll = () => {
            stakeValue.current = 0
            profit.current = 0
            profitData.current = 0
            setPopupDisplay(false)
            setHideLoading(false)
            setSubmitButtonDisable(false)
            setPlacingBets([])
        }
        const getLatestDataFound = () => {
            const teamm = [' - Odd', ' - Even'].some(el => teamname.current.includes(el))
                ? teamname.current.split(' - ')[0]
                : teamname.current;

            return changeDataRef.current[betTypeFromArray]?.section.find(item => item.nat === teamm);
        };

        const placeBet = async () => {


            setSubmitButtonDisable(true)
            setHideLoading(true)

            let datafound = getLatestDataFound()

            backOrLay = betType === 'ODDEVEN' ? (backOrLay === 'back' ? 'odd' : 'even') : backOrLay;
            let match_odd = datafound?.[backOrLay]?.[runnerRowDefault.current];



            const minMaxCheck = () => {
                if (maxValue?.[betType] && maxValue[betType] < stakeValue.current) {
                    setHideLoading(true)

                    Notify('Min Max Bet Limit Exceed', null, null, 'danger');
                    resetAll()


                    return false;

                } else if (minValue?.[betType] && minValue[betType] > stakeValue.current) {
                    setHideLoading(true)

                    Notify('Min Max Bet Limit Exceed', null, null, 'danger');
                    resetAll()

                    return false;

                }
                return true;

            }

            if (!minMaxCheck()) {
                return;
            }

            if (betType === 'ODDS') {

                const oddFunction = () => {

                    let datafound = getLatestDataFound(); // Re-fetch latest data in each call


                    backOrLay = betType === 'ODDEVEN' ? (backOrLay === 'back' ? 'odd' : 'even') : backOrLay;
                    const match_odd = {...datafound[backOrLay]?.[runnerRowDefault.current]};


                    if (datafound.gstatus !== 'ACTIVE' && datafound.gstatus !== '') {

                        Notify('Bet not Confirm. Game Suspended', null, null, 'danger');
                        resetAll()

                        return false;
                    }

                    if (match_odd) {



                        if (backOrLay === 'back' && odds > match_odd.odds) {

                            Notify('Odds Value change, Bet not Confirm!', null, null, 'danger');
                            resetAll()
                            return false;
                        }


                        if (backOrLay === 'lay' && odds < match_odd.odds) {

                            Notify('Odds Value change, Bet not Confirm!', null, null, 'danger');
                            resetAll()
                            return false;
                        }
                        calculateProfit(stakeValue.current, match_odd.odds)
                        odds = match_odd.odds

                    }
                    return true;
                }


                const isOddValid = await new Promise((resolve) => {
                     setTimeout(() => {
                        resolve(oddFunction());
                    }, 5000);

                });

                if (!isOddValid) return;

            } else if (betType === 'BOOKMAKER') {
                if (datafound && datafound?.gstatus !== 'ACTIVE' && datafound?.gstatus !== '') {

                    Notify('Bet not Confirm. Game Suspended', null, null, 'danger');
                    resetAll()

                    return;
                }
                if (match_odd !== undefined) {

                    if (odds !== match_odd.odds) {

                        Notify('Odds Value change, Bet not Confirm!', null, null, 'danger');
                        resetAll()

                        return;
                    }

                }
            } else {


                if (datafound.gstatus !== 'ACTIVE' && datafound.gstatus !== '') {

                    Notify('Bet not Confirm. Game Suspended', null, null, 'danger');
                    resetAll()

                    return;
                }
                if (match_odd !== undefined) {

                    if (odds !== match_odd.odds) {

                        Notify('Odds Value change, Bet not Confirm!', null, null, 'danger');
                        resetAll()

                        return;

                    }

                }
            }


            const submit = () => {
                const data = {
                    sportId: sportList.id,
                    matchId: sportList.match_id,
                    isback: backOrLay === 'back' ? 1 : 0,
                    placeName: teamname?.current?.trim(),
                    placeName2: '',
                    odds: odds,
                    oddsk: oddsk.current,
                    profit: backOrLay === 'lay' ? loss.current : profitData.current,
                    loss: backOrLay === 'lay' ? profit.current : loss.current,
                    betRowNo: runnerRowDefault?.current,
                    isfancy: betType === 'SESSION' ? 0 : 1,
                    betType: betType,
                    betAmount: stakeValue.current,
                    rootClass: rootClassDefault.current
                };

                if (betType === 'fancy1') {
                    data.loss = stakeValue.current;
                }
                if (backOrLay === 'lay') {
                    data.profit = profit.current;
                    data.loss = loss.current;

                }
                if (betType.toLowerCase() === 'bookmaker' && backOrLay === 'lay') {

                }

                axiosFetch('betStore', 'post', null, data)
                    .then((data) => {


                        if (data.data.status === true) {
                            // setOddsTeamData(getExByTeamNameForCricket(ar_sectionData, 'ODDS', 'match_odds', sportList.id))
                            individualBetPlaceFetch(teamname.current)
                            Notify(data.data.msg, null, null, 'success');
                            if(typeof getBetListData === 'function'){

                                    getBetListData();
                            }

                            if (typeof callTeamDatas === 'function') {
                                const up = betType.toUpperCase()
                                const arrayBet = {
                                    'ODDS': 'match_odds',
                                    'BOOKMAKER': 'bookmaker',
                                    'TIED_MATCH': 'tied match'
                                }

                                callTeamDatas({[up]: arrayBet[up]})
                            }

                        } else {


                            Notify(data.data.msg, null, null, 'danger');


                        }

                        getBalance()
                        resetAll()
                        clearAllSelection();

                    }).catch((error) => {

                    resetAll()
                    clearAllSelection();

                })


            }

            if (betType === 'ODDS' || betType === 'BOOKMAKER' || betType === 'TIED_MATCH') {
                setHideLoading(true)

                submit()

            } else {
                submit()
            }

        };


        useEffect(() => {
            getStakeValue(setStakeValues);
        }, []);

        return (
            <div id="sidebar-right" className="col-md-3 sidebar-right"
                 ref={sidebarRef}
                 style={{
                     position: isFixed ? 'fixed' : 'relative',
                     top: isFixed ? '0' : 'auto',
                     right: isFixed ? '0' : 'auto',
                     width: isFixed ? '25.5%' : 'auto',
                 }}>
                <div className="ps">
                    <div className="sidebar-right-inner">
                        <div className="card m-b-10" style={{border: 'none'}}>
                            <div className="card-header"  onClick={toggleTV}>
                                <h6 className="card-title">
                                    Live Match
                                    <span className="float-right">
                                    <i className="fa fa-tv"></i> live stream started
                                </span>
                                </h6>
                            </div>
                            <div className={`tv-container collapse position-relative ${isTVVisible ? 'show' : ''}`}
                                 align="center">
                                <div className="loader1 loderTV"
                                     style={{display: isTVVisible ? 'block' : 'none'}}>
                                    <iframe className="video-iframe" width="100%" height="100%" src={`https://api.codewithabhilash.com/play2.html?eid=${gameId}`} />


                                </div>
                            </div>
                        </div>

                        {/* Place Bet Section */}
                        {popupDisplay && (
                            <div className={`card m-b-10 place-bet betSlipBox ${backOrLay}`}>
                                <div className="card-header">
                                    <h6 className="card-title d-inline-block">Place Bet</h6>
                                </div>
                                <div className="d-block w-100">
                                    {hideLoading && (
                                        <div className="loader1 loderBet"></div>
                                    )}
                                    <div className="table-responsive placeBetSilp">
                                        <form id="placeBetSilp" className="bg-transparent">
                                            <table className="coupon-table table table-borderedless bg-transparent">
                                                <thead className="bg-transparent">
                                                <tr>
                                                    <th></th>
                                                    <th style={{width: '35%', textAlign: 'left'}}>(Bet for)</th>
                                                    <th style={{width: '25%', textAlign: 'left'}}>Odds</th>
                                                    <th style={{width: '15%', textAlign: 'left'}}>Stake</th>
                                                    <th style={{width: '15%', textAlign: 'right'}}>Profit</th>
                                                </tr>
                                                </thead>
                                                <tbody className="bg-transparent">
                                                <tr className="bg-transparent">
                                                    <td className="text-center">
                                                        <a href="#" onClick={clearAllSelection} className="text-danger">
                                                            <i className="fa fa-times"></i>
                                                        </a>
                                                    </td>
                                                    <td id="ShowRunnderName">{teamname.current}</td>
                                                    <td className="bet-odds">
                                                        <div className="form-group">
                                                            <input placeholder="0" value={odds || ''} type="text"
                                                                   required maxLength="4"
                                                                   id="ShowBetPrice" readOnly className="amountint"
                                                                   style={{width: '45px', verticalAlign: 'middle'}}/>
                                                            <div
                                                                className="spinner-buttons input-group-btn btn-group-vertical">
                                                                <button type="button"
                                                                        className="custom-btn-spinner btn btn-xs btn-default">
                                                                    <i
                                                                        className="fa fa-angle-up"></i></button>
                                                                <button type="button"
                                                                        className="custom-btn-spinner btn btn-xs btn-default">
                                                                    <i
                                                                        className="fa fa-angle-down"></i></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="bet-stakes">
                                                        <div className="form-group bet-stake">
                                                            <input maxLength="10" required id="stakeValue"
                                                                   value={stakeValue?.current}
                                                                   onChange={handleStakeChange} type="text"/>
                                                        </div>
                                                    </td>
                                                    <td className="text-right bet-profit"
                                                        id="profitData">{profit.current}</td>
                                                </tr>
                                                <tr className="bg-transparent">
                                                    <td colSpan="5" className="value-buttons" style={{padding: '5px'}}>
                                                        {Object.values(stakeValues).map((value, index) => (
                                                            <button key={index} type="button"
                                                                    className="btn btn-secondary m-l-5 m-b-5 font-weight-bold"
                                                                    value={value.val}
                                                                    onClick={() => handleStakeChange({target: {value: value.val}})}>
                                                                {value.label}
                                                            </button>
                                                        ))}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="col-md-12">
                                                <button type="button" onClick={() => setStakeShowModal(true)}
                                                        className="btn btn-sm btn-info float-left">Edit
                                                </button>
                                                <button type="button" disabled={submitButtonDisable} onClick={placeBet}
                                                        className="btn btn-sm btn-success float-right m-b-5">Submit
                                                </button>
                                                <button type="button" onClick={clearAllSelection}
                                                        className="btn btn-sm btn-danger float-right mr-1">Reset
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <StakeModal
                                    show={stakeshowModal}
                                    handleClose={() => setStakeShowModal(false)}

                                />
                            </div>


                        )}

                        {/* My Bet Section */}
                        <div className="card m-b-10 my-bet">
                            <div className="card-header">
                                <h6 className="card-title d-inline-block">My Bet</h6>
                            </div>
                            <div className="card-body">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="table-responsive">
                                            <table
                                                className="table table-striped ListAllHideHeader coupon-table table table-borderedless">
                                                <thead>

                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="tbodyScroll">
                                                            <table
                                                                className="table coupon-table table table-borderedless">
                                                                <thead>
                                                                <tr>
                                                                    <th>Runner</th>
                                                                    <th>Type</th>
                                                                    <th>Bet Type</th>
                                                                    <th>Odds</th>
                                                                    <th>Stack</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {myBetModel.length > 0 ? (
                                                                    myBetModel.map((data, index) => {
                                                                        const className = data.bet_side === "LAY" ? "lay" : "back";
                                                                        return (
                                                                            <tr key={index} className={className}>
                                                                                <td>{data.team_name}</td>
                                                                                <td>{data.bet_type}</td>
                                                                                {data.type === data.bet_type && data.bet_type === "SESSION" ? (
                                                                                    <>
                                                                                        <td>{data.bet_side === "LAY" ? "NO" : "YES"}</td>
                                                                                        <td>{data.bet_odds} / {data.bet_oddsk}</td>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <td>{data.bet_side}</td>
                                                                                        <td>{data.bet_odds}</td>
                                                                                    </>
                                                                                )}
                                                                                <td>{data.bet_amount}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <th colSpan="5">No Records Found</th>
                                                                    </tr>
                                                                )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

export default RightSideBarSports;
