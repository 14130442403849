// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.dataTable thead .sorting {
    background-image: none;
}
.row.row5 {
    margin-left: 0px;
    margin-right: 5px;
}
.dataTables_wrapper .dataTables_filter {
    margin-top: 0px;
}
#account_statement_list_wrapper  .col-md-4{width: 35%;float: left;}
#account_statement_list_wrapper  .col-md-8{width: 65%;float: left;}
#account_statement_list_wrapper  .col-md-5{width: 64%;float: left;}
#account_statement_list_wrapper  .col-md-7{width: 35%;float: left;}
.dataTables_info{font-size: 10px;margin-bottom: 20px;}
.dataTables_wrapper .dataTables_paginate .paginate_button{padding: 0px 2px !important;}
.Checklogin{display:none !important;}

.loginCheckkar,
.Checklogin{display:none !important;}
label{
    color: #333;
}
/*.ml-1{*/
/*    margin-left: 5px;*/
/*}*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
        line-height: 23px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/mobile/datatable.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA,2CAA2C,UAAU,CAAC,WAAW,CAAC;AAClE,2CAA2C,UAAU,CAAC,WAAW,CAAC;AAClE,2CAA2C,UAAU,CAAC,WAAW,CAAC;AAClE,2CAA2C,UAAU,CAAC,WAAW,CAAC;AAClE,iBAAiB,eAAe,CAAC,mBAAmB,CAAC;AACrD,0DAA0D,2BAA2B,CAAC;AACtF,YAAY,uBAAuB,CAAC;;AAEpC;YACY,uBAAuB,CAAC;AACpC;IACI,WAAW;AACf;AACA,SAAS;AACT,wBAAwB;AACxB,IAAI;AACJ;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":["table.dataTable thead .sorting {\r\n    background-image: none;\r\n}\r\n.row.row5 {\r\n    margin-left: 0px;\r\n    margin-right: 5px;\r\n}\r\n.dataTables_wrapper .dataTables_filter {\r\n    margin-top: 0px;\r\n}\r\n#account_statement_list_wrapper  .col-md-4{width: 35%;float: left;}\r\n#account_statement_list_wrapper  .col-md-8{width: 65%;float: left;}\r\n#account_statement_list_wrapper  .col-md-5{width: 64%;float: left;}\r\n#account_statement_list_wrapper  .col-md-7{width: 35%;float: left;}\r\n.dataTables_info{font-size: 10px;margin-bottom: 20px;}\r\n.dataTables_wrapper .dataTables_paginate .paginate_button{padding: 0px 2px !important;}\r\n.Checklogin{display:none !important;}\r\n\r\n.loginCheckkar,\r\n.Checklogin{display:none !important;}\r\nlabel{\r\n    color: #333;\r\n}\r\n/*.ml-1{*/\r\n/*    margin-left: 5px;*/\r\n/*}*/\r\n@media screen and (-webkit-min-device-pixel-ratio:0) {\r\n    input[type=\"date\"].form-control, input[type=\"time\"].form-control, input[type=\"datetime-local\"].form-control, input[type=\"month\"].form-control {\r\n        line-height: 23px !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
