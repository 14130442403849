import React, {useContext} from 'react';
import {
    chunkArray,

    generateBackAndLayFunction,
    getSize,
    handleShowRules, useFancyHideStatus
} from "../../../Common_Functions/Constants";
import {SportsContext} from "../../../Components/Context/SportsContext";
import {Link} from "react-router-dom";

const Fancy1 = ({
                    data,
                    sportList,
                    betPlaceStatus,
                    setDefaultTeamName,
                    setBetOddValue,
                    setbackOrLay,
                    teamNames,
                    setPopupDisplay
                }) => {
    const fancyHideStatus = useFancyHideStatus(sportList, data);
    const {runnerRowDefault, rootClassDefault, setBetType, oddsk, setBetTypeFromArray} = useContext(SportsContext);

    if (!data || !Array.isArray(data) || data['fancy1'] === undefined) return null;

    const mainValue = data['fancy1'];


    const ar_sectionData = mainValue.section;
    teamNames.current['fancy1'] = [];

    return (
        <div className="fancy-market row row5">
            <div className="col-12">
                <div>
                    <div className="market-title">
                        <span>fancy1</span>
                        <Link className="m-r-5 game-rules-icon" onClick={() => handleShowRules('Fancyrule')} to={null}>
                        <span>
                            <i className="fa fa-info-circle float-right"></i>
                        </span>
                        </Link>
                    </div>
                    <div className="row">
                        {chunkArray(ar_sectionData, 2).map((arr, valkey) => (
                            <div className={arr.length > 1 ? "col-6" : "col-6"} key={valkey}>
                                <div className="table-header">
                                    <div className="float-left country-name box-5"></div>
                                    <div className="box-1 float-left back text-center">
                                        <b>BACK</b>
                                    </div>
                                    <div className="box-1 float-left lay text-center">
                                        <b>LAY</b>
                                    </div>

                                </div>

                                <div className="table-body SESSION">
                                    {arr.map((oddsArr, key) => {
                                        const teamName = oddsArr?.nat?.trim();
                                        teamNames.current['SESSION'][teamName] = teamName;

                                        if (fancyHideStatus[oddsArr.sid] || !teamName || teamName.trim() === '') {
                                            return null;
                                        }

                                        let isSuspendedClass = '';
                                        let back = 0, lay = 0, backk = '0', layk = '0';
                                        oddsArr['back'] = [];
                                        oddsArr['lay'] = [];


                                        if (oddsArr.odds && oddsArr.odds.length > 0) {
                                            let no = 0;

                                            oddsArr.odds.forEach(a_value => {
                                                const tno = a_value.tno;
                                                if (a_value.otype === 'back') {

                                                    oddsArr['back'][tno] = a_value;
                                                    back = a_value.odds;
                                                    backk = a_value.size;
                                                } else if (a_value.otype === 'lay') {
                                                    oddsArr['lay'][no] = a_value;
                                                    lay = a_value.odds;
                                                    layk = a_value.size;
                                                    no++;
                                                }
                                            });
                                        }

                                        let totalOdds = parseFloat(back) + parseFloat(lay);
                                        if (sportList.match_suspend_fancy === 1 || sportList.match_suspend === 1) {
                                            totalOdds = 0;
                                        }

                                        const gstatus = oddsArr.gstatus ? oddsArr.gstatus.toUpperCase() : '';

                                        if (gstatus === 'SUSPENDED' || gstatus === 'BALL_RUNNING' || gstatus === 'MATCH-SUSPENDED') {
                                            totalOdds = 0;
                                        }

                                        if (totalOdds === 0) {
                                            isSuspendedClass = 'suspended suspend_box';
                                        }


                                        let backFunctionSes = [], layFunctionSes = [];
                                        backFunctionSes = generateBackAndLayFunction(totalOdds, oddsArr, 'back', teamName, 0, key, 'fancy1', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk, setBetTypeFromArray, 'fancy1');

                                        layFunctionSes = generateBackAndLayFunction(totalOdds, oddsArr, 'lay', teamName, 0, key, 'fancy1', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk, setBetTypeFromArray, 'fancy1');

                                        const betPlaceCheck = betPlaceStatus?.current?.[teamName];
                                        const fancyListValues = Object.values(betPlaceCheck?.fancy_list || []);

                                        const min_value = fancyListValues.length > 0 ? Math.min(...fancyListValues) : null;

                                        const get_fancy_session_value = betPlaceCheck?.fancy_list ? (
                                            <span className="span_fancy_session_value color-font-red">{min_value}</span>
                                        ) : null;

                                        return (
                                            <div key={key} className={`fancy-tripple`}>
                                                <div data-title={gstatus}
                                                     className={`table-row sessionchecksuspended ${isSuspendedClass}`}>
                                                    <div className="float-left country-name box-5"
                                                         style={{borderBottom: '0 none'}}>
                                                        <p className="m-b-0">
                                                            {betPlaceCheck?.bet ? (
                                                                <a className="link-session">
                                                                    {teamName}
                                                                </a>
                                                            ) : (
                                                                <a>{teamName}</a>
                                                            )}
                                                        </p>
                                                        <p className="m-b-0">{get_fancy_session_value}</p>
                                                    </div>
                                                    <div className="box-1 back float-left text-center"
                                                         onClick={backFunctionSes[0]}>
                                                        <span className="odd d-block">{back > 0 ? back : '-'}</span>
                                                        <span>{getSize(backk, false)}</span>
                                                    </div>
                                                    <div className="box-1 lay float-left text-center"
                                                         onClick={layFunctionSes[0]}>
                                                        <span className="odd d-block">{lay > 0 ? lay : '-'}</span>
                                                        <span>{layk > 0 ? getSize(layk, false) : ''}</span>
                                                    </div>

                                                    <div className="box-2 float-left text-right min-max"
                                                         style={{borderBottom: "0px none"}}>
                                                        <span className="d-block">Min: <span>{oddsArr.min}&nbsp;</span></span>
                                                        <span
                                                            className="d-block">Max: <span>{getSize(oddsArr.max)}&nbsp;</span></span>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>


    );
};
export default Fancy1;