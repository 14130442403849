// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control:focus {
    border: 1px solid #e2e2e2;
}

.card {
    min-width: 100%;
}

.card-body {
    padding: 0px !important;
}

/*.listing-grid{padding: 0px;}*/
.main-container {
    padding-top: 0px!important;
}

.ListAllHideHeader {
    width: 100%;
}

.ListAllHideHeader > thead {
    display: none !important;
}

.ListAllHideHeader > tbody > tr > td {
    padding: 0px !important;
}

.tbodyScroll {
    max-height: 700vh;
    overflow-y: scroll;
}

.laybettingbox .lay.betting-pink,
.backbattingbox .back.betting-blue {
    border-radius: 0 !important;
}

.table-responsive {
    overflow-x: clip !important;
}

.match_box {
    padding: 0px !important;
}

.col-sm-2 {
    width: 16.50%;
    float: left;
}

.col-sm-3 {
    width: 24.50%;
    float: left;
}

.col-sm-5 {
    width: 41.50%;
    float: left;
}

@media only screen and (max-width: 768px) {
    .lastball {
        padding: 2px 5px !important;
        color: #fff;
        border-radius: 50%;
        font-size: 10px !important;
        margin: 1px;
    }

    .sbTeamName1, .sbTeamName2, .sbRun1, .sbRunRate1, .sbStatus, .sbRun2, .sbRunRate2 {
        font-size: 10px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/sports.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA,+BAA+B;AAC/B;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;;IAEI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI;QACI,2BAA2B;QAC3B,WAAW;QACX,kBAAkB;QAClB,0BAA0B;QAC1B,WAAW;IACf;;IAEA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".form-control:focus {\r\n    border: 1px solid #e2e2e2;\r\n}\r\n\r\n.card {\r\n    min-width: 100%;\r\n}\r\n\r\n.card-body {\r\n    padding: 0px !important;\r\n}\r\n\r\n/*.listing-grid{padding: 0px;}*/\r\n.main-container {\r\n    padding-top: 0px!important;\r\n}\r\n\r\n.ListAllHideHeader {\r\n    width: 100%;\r\n}\r\n\r\n.ListAllHideHeader > thead {\r\n    display: none !important;\r\n}\r\n\r\n.ListAllHideHeader > tbody > tr > td {\r\n    padding: 0px !important;\r\n}\r\n\r\n.tbodyScroll {\r\n    max-height: 700vh;\r\n    overflow-y: scroll;\r\n}\r\n\r\n.laybettingbox .lay.betting-pink,\r\n.backbattingbox .back.betting-blue {\r\n    border-radius: 0 !important;\r\n}\r\n\r\n.table-responsive {\r\n    overflow-x: clip !important;\r\n}\r\n\r\n.match_box {\r\n    padding: 0px !important;\r\n}\r\n\r\n.col-sm-2 {\r\n    width: 16.50%;\r\n    float: left;\r\n}\r\n\r\n.col-sm-3 {\r\n    width: 24.50%;\r\n    float: left;\r\n}\r\n\r\n.col-sm-5 {\r\n    width: 41.50%;\r\n    float: left;\r\n}\r\n\r\n@media only screen and (max-width: 768px) {\r\n    .lastball {\r\n        padding: 2px 5px !important;\r\n        color: #fff;\r\n        border-radius: 50%;\r\n        font-size: 10px !important;\r\n        margin: 1px;\r\n    }\r\n\r\n    .sbTeamName1, .sbTeamName2, .sbRun1, .sbRunRate1, .sbStatus, .sbRun2, .sbRunRate2 {\r\n        font-size: 10px !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
