import React, {useContext, useEffect, useState} from "react";
import axiosFetch from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {CommonContext} from "../../Components/Context/CommonContext";
import Loader from "../../Components/Loader";
import {SportsContext} from "../../Components/Context/SportsContext";
import RightSideBarSports from "./RightSideBarSports";

import "../../css/sports.css"

const SportsLayout = ({currentOddValue=[],
                          setPlacingBets = null,
                          callTeamDatas =null,
    placingBets =  [],

    gameId = null,
                          setOddsTeamData = null,
                          children,
                          setSportList,
                          sportList,
                          teamNameCurrentBets,

                          individualBetPlaceFetch,
                          data,
                          betType,
                          backOrLay,
                          teamname,
                          odds,
                          teamNames,
                          setOdds,
                          setPopupDisplay,
                          popupDisplay,
                          maxValue = 1,
    minValue
                      }) => {


    const {match_id} = useParams();

    const [myBetModel, setMyBetModel] = useState({})



    const {formatDateTime} = useContext(CommonContext);
    const {showLoader} = useContext(SportsContext);
    const exposure = localStorage.getItem('exposure')
    useEffect(() => {
        const getData = async () => {
            await axiosFetch(`sport_data/${match_id}`, 'get')
                .then((res) => setSportList(res.data))
        }
        getData()
        // eslint-disable-next-line
    }, [match_id]);
    const getBetListData = async () => {
        await axiosFetch(`bet_data/${match_id}`, 'get', setMyBetModel)
    }
    useEffect(() => {



        getBetListData();
        // eslint-disable-next-line
    }, [exposure])


    return (
        <>
            <div className="row row-5">

                <div className="col-md-9 featured-box-detail sports-wrapper m-b-10">


                    <div className="game-heading">


                <span className="card-header-title">
                    {sportList?.match_name}
                </span>
                        {sportList.match_date_time && (
                            <span
                                className="float-right">{formatDateTime(new Date(sportList?.match_date_time))}</span>
                        )}


                        <span className="tvAdd position-relative" data-tv="0"></span>

                        <div id="scoreboard-box">


                            {/*<iframe style={{width:"100%", minHeight: "230px"}} scrolling="no" className="example"*/}
                            {/*        src={`https://bababetbook.in/score/score.php?eid=${match_id}`}></iframe>*/}
                        </div>

                    </div>
                    <div className="card">
                        <div className="card-body">

                            {children}

                        </div>
                    </div>
                    {showLoader === true &&

                        <Loader/>
                    }

                </div>
                <RightSideBarSports gameId={gameId} getBetListData={getBetListData} callTeamDatas={callTeamDatas} setOddsTeamData={setOddsTeamData} placingBets={placingBets} setPlacingBets={setPlacingBets}
                                    currentOddValue={currentOddValue} minValue={minValue} maxValue={maxValue}
                                    myBetModel={myBetModel} teamNameCurrentBets={teamNameCurrentBets}
                                    individualBetPlaceFetch={individualBetPlaceFetch} data={data}
                                    betType={betType}
                                    sportList={sportList} backOrLay={backOrLay} teamname={teamname}
                                    odds={odds}
                                    teamNames={teamNames}
                                    setOdds={setOdds}
                                    setPopupDisplay={setPopupDisplay} popupDisplay={popupDisplay}/>

            </div>

        </>
    )
}
export default SportsLayout;